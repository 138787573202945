import i18next from 'i18next'
import React, { useState, useRef, useEffect } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { useLocation } from 'react-router-dom'

import { WOWSWAP_ROUTE } from 'constants/routes/routes'
import { useActiveWeb3React } from 'hooks'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useRouterGuards } from 'hooks/useRouterGuards'

import * as S from './styleds'

const activeClassName = 'active-nav-link'

type NavItem = {
  id: string
  name: string
  link?: string
  targetBlank?: boolean
  subMenu?: NavItem[]
  isVisibleOnMobile?: boolean
}

const t = i18next.t.bind(i18next)

const nav: NavItem[] = [
  {
    id: 'trade',
    name: t('Trade'),
    subMenu: [
      {
        id: 'swap',
        name: t('Swap'),
        link: WOWSWAP_ROUTE.SWAP,
        isVisibleOnMobile: true
      },
      {
        id: 'portfolio',
        name: t('Portfolio'),
        link: WOWSWAP_ROUTE.PORTFOLIO,
        isVisibleOnMobile: true
      },
      {
        id: 'alerts',
        name: t('Alerts'),
        link: WOWSWAP_ROUTE.ALERTS
      },
      {
        id: 'pro',
        name: t('Pro'),
        link: WOWSWAP_ROUTE.PRO
      }
    ]
  },
  {
    id: 'earn',
    name: t('Earn'),
    link: WOWSWAP_ROUTE.EARN,
    isVisibleOnMobile: true
  },
  {
    id: 'dao',
    name: t('DAO'),
    link: WOWSWAP_ROUTE.GOVERNANCE_YOUR_WALLET
  },
  {
    id: 'x-degen',
    name: t('X-Degen'),
    link: WOWSWAP_ROUTE.XDEGEN
  },
  {
    id: 'docs',
    name: t('Docs'),
    link: 'https://docs.wowswap.io/',
    targetBlank: true
  }
]

const topMobileNav: NavItem[] = []

const sideMobileNav: NavItem[] = []

nav.forEach(el => {
  if (el.subMenu) {
    el.subMenu.forEach(subEl => {
      if (subEl.isVisibleOnMobile) {
        topMobileNav.push(subEl)
      } else {
        sideMobileNav.push(subEl)
      }
    })
  } else {
    if (el.isVisibleOnMobile) {
      topMobileNav.push(el)
    } else {
      sideMobileNav.push(el)
    }
  }
})

type Props = { position: 'top' | 'sidebar' }

export const Nav = ({ position }: Props) => {
  const { pathname } = useLocation()
  const { chainId, account } = useActiveWeb3React()
  const { isCanPro, isCanOpenGov, isCanOpenPortfolio, isCanOpenAlerts } = useRouterGuards(chainId, account)
  const [openMenu, setOpenMenu] = useState<string | null>(null)
  const rootRef = useRef(null)

  useOnClickOutside(rootRef, () => setTimeout(() => setOpenMenu(null), 0))

  useEffect(() => setOpenMenu(null), [pathname])

  const hiddenItems = [
    !isCanPro && 'pro',
    !isCanOpenGov && 'dao',
    !isCanOpenPortfolio && 'portfolio',
    !isCanOpenAlerts && 'alerts'
  ]

  const excludeHidden = (el: NavItem) => !hiddenItems.includes(el.id)

  const desktopNav = nav
    .filter(excludeHidden)
    .map(el => ({ ...el, subMenu: el.subMenu ? el.subMenu.filter(excludeHidden) : undefined }))

  const mobileNav = position === 'top' ? topMobileNav.filter(excludeHidden) : sideMobileNav.filter(excludeHidden)

  return (
    <>
      <S.DesktopNav ref={rootRef}>
        {desktopNav.map(({ id, name, link, targetBlank, subMenu }) => (
          <S.Item key={id} withSubMenuOpen={openMenu === id}>
            {subMenu ? (
              <>
                <S.ExpandableLink as="div" onClick={() => setOpenMenu(openMenu === id ? null : id)}>
                  {name}
                  {openMenu === id ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </S.ExpandableLink>
                <S.SubMenu isOpen={openMenu === id}>
                  {subMenu.map(({ id, name, link, targetBlank }) => (
                    <S.SubMenuLink
                      key={id}
                      to={{ pathname: link }}
                      target={targetBlank ? '_blank' : undefined}
                      activeClassName={activeClassName}
                    >
                      {name}
                    </S.SubMenuLink>
                  ))}
                </S.SubMenu>
              </>
            ) : (
              <S.Link
                to={{ pathname: link }}
                target={targetBlank ? '_blank' : undefined}
                activeClassName={activeClassName}
              >
                {name}
              </S.Link>
            )}
          </S.Item>
        ))}
      </S.DesktopNav>

      <S.MobileNav position={position}>
        {mobileNav.map(({ id, name, link, targetBlank }) => (
          <S.Item key={id}>
            <S.Link
              to={{ pathname: link }}
              target={targetBlank ? '_blank' : undefined}
              activeClassName={activeClassName}
            >
              {name}
            </S.Link>
          </S.Item>
        ))}
      </S.MobileNav>
    </>
  )
}
