import { Contract } from '@ethersproject/contracts'
import { ChainId } from '@wowswap-io/wowswap-sdk'
import { useMemo } from 'react'

import ENS_PUBLIC_RESOLVER_ABI from 'constants/abis/ens-public-resolver.json'
import ENS_ABI from 'constants/abis/ens-registrar.json'
import { ERC20_BYTES32_ABI } from 'constants/abis/erc20'
import ERC20_ABI from 'constants/abis/erc20.json'
import ERC20_PERMIT_ABI from 'constants/abis/erc20_permit.json'
import GOVERNANCE_ABI from 'constants/abis/governance.json'
import TREASURER_ABI from 'constants/abis/treasurer.json'
import XWOW_ABI from 'constants/abis/xWOW.json'
import { ZERO_ADDRESS } from 'constants/index'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from 'constants/multicall'
import { useActiveWeb3React } from 'hooks'
import { useSingleCallResult } from 'state/multicall/hooks'
import { getContract, getRouterContract, getReserveContract, getReserveFactoryContract } from 'utils'

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenPermitContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_PERMIT_ABI, withSignerIfPossible)
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useStakeTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, XWOW_ABI, withSignerIfPossible)
}

export function useTreasurerContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, TREASURER_ABI, withSignerIfPossible)
}

export function useGovernanceContract(address?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, GOVERNANCE_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.BSCTESTNET:
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}

export function useRouterContract() {
  const { account, chainId, library } = useActiveWeb3React()

  return useMemo(() => {
    if (!chainId || !library) return null

    return getRouterContract(chainId, library, account || undefined)
  }, [account, chainId, library])
}

function useReserveAddress() {
  const contract: Contract | null = useRouterContract()
  const reserveFactory = useSingleCallResult(contract, 'reserveFactory', undefined)

  return useMemo(() => {
    if (!reserveFactory.result) return null
    return reserveFactory.result?.[0]
  }, [reserveFactory])
}

function useReserveFactoryContract() {
  const { account, library } = useActiveWeb3React()
  const address = useReserveAddress()

  return useMemo(() => {
    if (!address || !library) return null

    return getReserveFactoryContract(address, library, account || undefined)
  }, [account, address, library])
}

export function useReserveContractForToken(lendableTokensAdressess: string) {
  const { account, library } = useActiveWeb3React()
  const contract: Contract | null = useReserveFactoryContract()
  const reserve = useSingleCallResult(contract, 'getReserve', [lendableTokensAdressess])

  return useMemo(() => {
    if (!reserve.result) return null
    if (!library) return null
    if (reserve.result?.[0] === ZERO_ADDRESS) return null

    return getReserveContract(reserve.result[0], library, account || undefined)
  }, [account, library, reserve])
}
