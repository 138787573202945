import React, { useRef } from 'react'

import BuyWOWButton from 'components/Header/BuyWOWButton'
import { Nav } from 'components/Header/Nav'
import { ShareSocial } from 'components/ShareSocial'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useToggle from 'hooks/useToggle'

import * as S from './styleds'

type Props = { WOWPrice: number | null }

export const SideMenu = ({ WOWPrice }: Props) => {
  const node = useRef<HTMLDivElement>(null)
  const [open, toggle] = useToggle(false)

  useOnClickOutside(node, open ? toggle : undefined)

  return (
    <S.StyledSideMenu ref={node}>
      <S.StyledSideMenuButton onClick={toggle}>
        <S.StyledMenuIcon />
      </S.StyledSideMenuButton>

      <S.SideMenuLayout open={open}>
        <S.CloseBtn onClick={toggle} />

        <S.NavWrapper>
          <BuyWOWButton WOWPrice={WOWPrice} />
          <Nav position="sidebar" />
        </S.NavWrapper>

        <ShareSocial onClick={toggle} embed />
      </S.SideMenuLayout>
    </S.StyledSideMenu>
  )
}
