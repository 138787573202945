import { getAddress } from '@ethersproject/address'
import { BigNumber } from '@ethersproject/bignumber'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { ChainId, JSBI, Percent } from '@wowswap-io/wowswap-sdk'

import RESERVE_ABI from 'constants/abis/reserve.json'
import RESERVE_FACTORY_ABI from 'constants/abis/reserveFactory.json'
import ROUTER_ABI from 'constants/abis/router.json'
import { ROUTER_ADDRESS } from 'constants/index'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

const ETHERSCAN_PREFIXES: { [chainId in ChainId]: string } = {
  [ChainId.ETHEREUM]: 'https://etherscan.com',
  [ChainId.ROPSTEN]: 'https://ropsten.etherscan.com',
  [ChainId.MAINNET]: 'https://bscscan.com',
  [ChainId.LOCALNET]: '',
  [ChainId.HECO]: 'https://hecoinfo.com',
  [ChainId.MATIC]: 'https://explorer-mainnet.maticvigil.com',
  [ChainId.BSCTESTNET]: 'https://testnet.bscscan.com',
  [ChainId.AVALANCE]: 'https://snowtrace.io',
  [ChainId.FUJI]: '',
  [ChainId.IOTEX]: 'https://iotexscan.io',
  [ChainId.ANDROMEDA]: '',
  [ChainId.STARDUST]: 'https://stardust-explorer.metis.io',
  [ChainId.KAVATESTNET]: 'https://explorer.evm-alpha.kava.io',
  [ChainId.KAVA]: 'https://explorer.kava.io'
}

export function getEtherscanLink(chainId: ChainId, data: string, type: 'transaction' | 'token' | 'address'): string {
  const prefix = ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[56]

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

// account is not optional
function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

// account is optional
export function getRouterContract(chainId: ChainId, library: Web3Provider, account?: string): Contract {
  return getContract(ROUTER_ADDRESS[chainId], ROUTER_ABI, library, account)
}

export function getReserveContract(address: string, library: Web3Provider, account?: string): Contract {
  return getContract(address, RESERVE_ABI, library, account)
}

export function getReserveFactoryContract(address: string, library: Web3Provider, account?: string): Contract {
  return getContract(address, RESERVE_FACTORY_ABI, library, account)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const defined = <T>(something: T | undefined): something is T => typeof something !== 'undefined'
