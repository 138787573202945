import React from 'react'
import styled from 'styled-components'

import Clock from 'assets/images/clock.gif'

const LoaderStyle = styled.div<{ size: string; margin?: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};

  margin: ${({ margin }) => margin} !important;

  > img {
    height: ${({ size }) => size};
    width: ${({ size }) => size};
  }
`

export default function Loader({ size = '40px', margin = '0' }: { size?: string; margin?: string }) {
  return (
    <LoaderStyle size={size} margin={margin}>
      <img src={Clock} alt="Load" />
    </LoaderStyle>
  )
}
