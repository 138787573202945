import { DefaultTheme } from 'styled-components'

import light from './light'

const theme: DefaultTheme = {
  ...light,
  mode: 'avalanche',
  colors: {
    ...light.colors,
    bg2: '#E84142'
  },
  background: {
    image: `linear-gradient(90deg, #FF6262 0%, #E84141 100%)`
  }
}

export default theme
