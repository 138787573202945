import * as Sentry from '@sentry/react'
import React from 'react'

import ErrorFallback from 'components/error/ErrorFallback'

const AppErrorBoundary: React.FC<unknown> = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={errorData => (
        <ErrorFallback
          error={errorData.error}
          resetErrorBoundary={() => {
            window.location.reload()
          }}
        />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default AppErrorBoundary
