import { DialogOverlay, DialogContent } from '@reach/dialog'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { animated, useTransition } from 'react-spring'
import styled, { css } from 'styled-components'
import '@reach/dialog/styles.css'

const AnimatedDialogOverlay = animated(DialogOverlay)

const StyledDialogOverlay = styled(AnimatedDialogOverlay)`
  &[data-reach-dialog-overlay] {
    z-index: 10;
    background-color: transparent;
    overflow: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 100vh;

    background: ${({ theme }) => theme.colors.modalBG};
    opacity: 0.4;
  }
`
const DialogContentWrapper = styled.div<{ paddingBottom?: string }>`
  height: 100%;
  padding-bottom: ${({ paddingBottom }) => paddingBottom || '36px'};

  ${({ theme }) => theme.mediaWidth.upToSmall`
		padding-bottom: 16px;
	`}
`

const AnimatedDialogContent = animated(DialogContent)
// destructure to not pass custom props to Dialog DOM element
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledDialogContent = styled(({ minHeight, maxHeight, mobile, isOpen, maxWidth, height, balloon, ...rest }) => (
  <AnimatedDialogContent {...rest} />
)).attrs({
  'aria-label': 'dialog'
})`
  &[data-reach-dialog-content] {
		position: relative;
    margin: 2rem 0;
    border: 4px solid ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.bg0};
    padding: 36px;
		padding-bottom: 0;

    ${({ theme }) => {
      if (theme.mode === 'metis') {
        return `width: 100%;`
      }

      return `width: 50vw;`
    }}

    ${({ theme }) =>
      theme.mode === 'dark' &&
      `
      border: none;
      box-shadow: 0px 0px 100px rgb(243 186 47 / 50%);
    `}

    align-self: center;

		max-width: ${({ maxWidth }) => maxWidth || '420px'};	
		
    overflow: unset;

    ${({ minHeight }) =>
      minHeight &&
      css`
        min-height: ${minHeight}vh;
      `}

		height: ${({ height }) => height || ''};

    display: flex;
    border-radius: 20px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 65vw;
      // margin: 0;
			align-self: flex-start;
			padding: 16px;
			padding-bottom: 0;
			margin-left: 12px;
			margin-right: 12px;
    `}
    ${({ theme, mobile }) => theme.mediaWidth.upToSmall`
      width:  85vw;
      ${mobile &&
        css`
          width: 100vw;
          border-radius: 20px;
          // border-bottom-left-radius: 0;
          // border-bottom-right-radius: 0;
        `}				
    `}

		${({ balloon }) => {
      if (balloon) {
        return `
						width: 100%;
						padding: 0;
						margin: 0;
						
						background: none;
						border: none;
						border-radius: 0;
					`
      } else {
        return ``
      }
    }}

		${({ theme, balloon }) => theme.mediaWidth.upToSmall`
			${balloon &&
        `
				width: 100%;
				padding: 0;
				margin: 142px 22px 0 22px;
			`}
		`}
  }
`

interface ModalProps {
  height?: string
  maxWidth?: string
  isOpen: boolean
  onDismiss: (event?: React.SyntheticEvent) => void
  minHeight?: number | false
  maxHeight?: number
  initialFocusRef?: React.RefObject<any>
  children?: React.ReactNode
  paddingBottom?: string
  balloon?: boolean
}

export default function Modal({
  height,
  maxWidth,
  isOpen,
  onDismiss,
  minHeight = false,
  maxHeight = 50,
  initialFocusRef,
  children,
  paddingBottom,
  balloon
}: ModalProps) {
  const fadeTransition = useTransition(isOpen, null, {
    config: { duration: 200 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  return (
    <>
      {fadeTransition.map(
        ({ item, key, props }) =>
          item && (
            <StyledDialogOverlay key={key} style={props} onDismiss={onDismiss} initialFocusRef={initialFocusRef}>
              <StyledDialogContent
                aria-label="dialog content"
                minHeight={minHeight}
                maxHeight={maxHeight}
                mobile={isMobile}
                maxWidth={maxWidth}
                height={height}
                balloon={balloon}
              >
                {/* prevents the automatic focusing of inputs on mobile by the reach dialog */}
                {!initialFocusRef && isMobile ? <div tabIndex={1} /> : null}
                <div style={{ width: '100%', height: '100%' }}>
                  <DialogContentWrapper paddingBottom={paddingBottom}>{children}</DialogContentWrapper>
                </div>
              </StyledDialogContent>
            </StyledDialogOverlay>
          )
      )}
    </>
  )
}
