import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'

import application from 'state/application/reducer'
import burn from 'state/burn/reducer'
import earn from 'state/earn/reducer'
import financial from 'state/financial/reducer'
import { updateVersion } from 'state/global/actions'
import lists from 'state/lists/reducer'
import multicall from 'state/multicall/reducer'
import notifications from 'state/notifications/reducer'
import stake from 'state/stake/reducer'
import trade from 'state/trade/reducer'
import transactions from 'state/transactions/reducer'
import user from 'state/user/reducer'

const PERSISTED_KEYS: string[] = ['user', 'transactions']

const store = configureStore({
  reducer: {
    application,
    user,
    transactions,
    burn,
    multicall,
    lists,
    earn,
    trade,
    stake,
    financial,
    notifications
  },
  middleware: [...getDefaultMiddleware({ thunk: true }), save({ states: PERSISTED_KEYS })],
  preloadedState: load({ states: PERSISTED_KEYS })
})

store.dispatch(updateVersion())

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
