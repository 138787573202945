import { DefaultTheme } from 'styled-components'

import base from './base'

const theme: DefaultTheme = {
  ...base,
  mode: 'dark',
  colors: {
    ...base.colors,
    shadow1: '#2F80ED',

    white: base.colors.white,
    black: base.colors.black,

    text1: base.colors.white,
    text2: base.colors.orange,
    text3: base.colors.red,
    text4: base.colors.grey1,

    // backgrounds / greys
    bg0: base.colors.dark,
    fg0: base.colors.black,
    bg1: base.colors.black,
    fg1: base.colors.black,
    bg2: base.colors.orange,
    fg2: base.colors.black,
    bg3: base.colors.black,
    fg3: base.colors.black,
    bg4: '#E6AE25',
    fg4: base.colors.black,
    bg5: '#FCEEBF',
    fg5: base.colors.black,
    bg6: 'linear-gradient(193.68deg,#2a1926 0.68%,#1f121c 100.48%)',
    fg6: base.colors.black,
    modalBG: '#00000099',
    advancedBG: 'rgba(255,223,248,0.6)',
    pageTitelBG: 'transparent',

    //primary colors
    primary1: base.colors.orange,
    primary4: base.colors.red,
    primary5: base.colors.green,
    primary6: '#ffdff5',

    // color text
    primaryText1: '#FFFFFF',
    buttonText: base.colors.light,

    // secondary colors
    secondary1: base.colors.grey2,

    red: '#E85A44'
  },
  background: {
    image: `linear-gradient(100deg, #000000 0%, #000000 100%)`
  },
  typography: {
    fontFamily: {
      primary: "'Roboto', sans-serif",
      secondary: "'Roboto', sans-serif"
    }
  }
}

export default theme
