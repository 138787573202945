import React, { useCallback, useContext } from 'react'
import { ExternalLink as LinkIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled, { css, ThemeContext } from 'styled-components'

import WalletConnectIcon from 'assets/images/walletConnectIcon.svg'
import { ButtonSecondary } from 'components/Button'
import Copy from 'components/Copy/Copy'
import { IconClose } from 'components/Icons'
import Identicon from 'components/Identicon'
import { AutoRow } from 'components/Row'
import { ExternalLink, TYPE } from 'components/Shared'
import { TextBase, TextBaseXl } from 'components/Text'
import { Title } from 'components/Title'
import { connectorsByWallets } from 'connectors'
import { LOCAL_STORAGE_KEY } from 'constants/index'
import { useActiveWeb3React } from 'hooks'
import { useGetWalletName } from 'hooks/useWalletDetector'
import { AppDispatch } from 'state'
import { clearAllTransactions } from 'state/transactions/actions'
import { shortenAddress, getEtherscanLink } from 'utils'

import Transaction from './Transaction'

const CloseIcon = styled.div`
  position: absolute;
  top: -26px;
  right: -22px;

  display: flex;

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      top: -17px;
      right: -15px;
    `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
		top: -4px;
		right: -4px;
  `};
`
const UpperSection = styled.section`
  position: relative;
  margin-bottom: 10px;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 700;
  }
`

const InfoCard = styled.div`
  position: relative;
`

const AccountGroupingRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  margin-bottom: 13px;
  justify-content: space-between;
  align-items: center;

  font-weight: 600;
  color: ${({ theme }) => theme.colors.text1};

  div {
    ${({ theme }) => theme.flexRowNoWrap}
    align-items: center;
  }
  :nth-child(2) {
    margin-bottom: 18px;
  }
  :last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
		margin-bottom: 17px;
	`};
`

const AccountSection = styled.div`
  padding: 9px 9px 14px 15px;

  background-color: ${({ theme }) => theme.colors.bg1};
  border-radius: 10px;

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      margin-top: 10px;
    `};
`

const YourAccount = styled.div`
  h5 {
    margin: 0 0 1rem 0;
    font-weight: 600;
  }

  h4 {
    margin: 0;
    font-weight: 700;
  }
`

const LowerSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  flex-grow: 1;

  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;

  h5 {
    margin: 0;

    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: ${({ theme }) => theme.colors.text3};
  }
`

const LowerSectionText = styled(TextBaseXl)`
  font-weight: bold;

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      font-weight: normal;
      font-size: 18px;
    `};
`

const AccountControl = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 0;
  width: 100%;

  font-weight: bold;
  font-size: 22px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.text1};

  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      font-size: 18px;
    `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
		font-size: 20px;
		line-height: 100%;
	`};
`

const AddressLink = styled(ExternalLink)<{ hasENS: boolean; isENS: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 22px;

  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.text2};

  transition: all 0.4s ease;
  :hover {
    color: ${({ theme }) => theme.colors.text3};
    text-decoration: none !important;
  }

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      font-size: 14px;
    `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
		font-size: 14px;
		line-height: 100%;
	`};
`

const WalletAction = styled(ButtonSecondary)`
  width: fit-content;
  margin-left: 8px;
  padding: 5px 15px;
  height: 26px;

  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.white};

  border: none;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.primary1};
  transition: all 0.2s ease;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.primary4};
    transition: all 0.2s ease;
  }

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      font-size: 14px;
    `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
		height: 23px;
		padding: 5px 12px;

		font-size: 14px;
		line-height: 100%;
	`};
`

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  flex: 0 0 28px;
  width: 28px;
  height: 28px;

  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
  & > div {
    width: 28px;
    height: 28px;
    justify-content: center;
  }
`

const TransactionListWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
  padding-right: 15px;
  max-height: 400px;
  overflow: auto;
`

const LinkIconWraper = styled.div`
  & > svg {
    width: 23px;
    height: 23px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
		& > svg {
			width: 16px;
			height: 16px;
		}
	`};
`

function renderTransactions(transactions: string[]) {
  return (
    <TransactionListWrapper>
      {transactions.map((hash, i) => {
        return <Transaction key={i} hash={hash} />
      })}
    </TransactionListWrapper>
  )
}

interface AccountDetailsProps {
  toggleWalletModal: () => void
  pendingTransactions: string[]
  confirmedTransactions: string[]
  ENSName?: string
  openOptions: () => void
}

export default function AccountDetails({
  toggleWalletModal,
  pendingTransactions,
  confirmedTransactions,
  ENSName,
  openOptions
}: AccountDetailsProps) {
  const { chainId, account, connector } = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch<AppDispatch>()
  const walletName = useGetWalletName()
  const { t } = useTranslation()

  function formatConnectorName() {
    return <TextBase color={theme.colors.text3}>Connected with {walletName}</TextBase>
  }

  function getStatusIcon() {
    if (connector === connectorsByWallets.INJECTED) {
      return (
        <IconWrapper size={28}>
          <Identicon />
        </IconWrapper>
      )
    } else if (connector === connectorsByWallets.WALLETCONNECT) {
      return (
        <IconWrapper size={28}>
          <img src={WalletConnectIcon} alt={'wallet connect logo'} />
        </IconWrapper>
      )
    }
    return null
  }

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) dispatch(clearAllTransactions({ chainId }))
  }, [dispatch, chainId])

  return (
    <>
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <IconClose />
        </CloseIcon>
        <Title>{t('Account')}</Title>
        <AccountSection>
          <YourAccount>
            <InfoCard>
              <AccountGroupingRow>
                {formatConnectorName()}
                <div>
                  {connector !== connectorsByWallets.INJECTED &&
                    connector !== connectorsByWallets.METAMASK_FAKE_HARDWARE && (
                      <WalletAction
                        onClick={() => {
                          ;(connector as any).close()
                          window.localStorage.removeItem(LOCAL_STORAGE_KEY.CONNECTOR)
                        }}
                      >
                        {t('Disconnect')}
                      </WalletAction>
                    )}
                  <WalletAction
                    onClick={() => {
                      openOptions()
                    }}
                  >
                    {t('Change')}
                  </WalletAction>
                </div>
              </AccountGroupingRow>
              <AccountGroupingRow id="web3-account-identifier-row">
                <AccountControl>
                  {ENSName ? (
                    <>
                      <div>
                        {getStatusIcon()}
                        <p> {ENSName}</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        {getStatusIcon()}
                        <p> {account && shortenAddress(account)}</p>
                      </div>
                    </>
                  )}
                </AccountControl>
              </AccountGroupingRow>
              <AccountGroupingRow>
                {ENSName ? (
                  <>
                    <AccountControl>
                      <div>
                        {account && (
                          <Copy toCopy={account}>
                            <span style={{ marginLeft: '10px' }}>Copy Address</span>
                          </Copy>
                        )}
                        {chainId && account && (
                          <AddressLink
                            hasENS={!!ENSName}
                            isENS={true}
                            href={chainId && getEtherscanLink(chainId, ENSName, 'address')}
                          >
                            <LinkIcon size={19} />
                            <span style={{ marginLeft: '11px' }}>{t('View on explorer')}</span>
                          </AddressLink>
                        )}
                      </div>
                    </AccountControl>
                  </>
                ) : (
                  <>
                    <AccountControl>
                      <div>
                        {account && (
                          <Copy toCopy={account}>
                            <span style={{ marginLeft: '10px' }}>Copy Address</span>
                          </Copy>
                        )}
                        {chainId && account && (
                          <AddressLink
                            hasENS={!!ENSName}
                            isENS={false}
                            href={getEtherscanLink(chainId, account, 'address')}
                          >
                            <LinkIconWraper>
                              <LinkIcon size={19} />
                            </LinkIconWraper>
                            <span style={{ marginLeft: '11px' }}>View on explorer</span>
                          </AddressLink>
                        )}
                      </div>
                    </AccountControl>
                  </>
                )}
              </AccountGroupingRow>
            </InfoCard>
          </YourAccount>
        </AccountSection>
      </UpperSection>

      <AccountSection>
        {!!pendingTransactions.length || !!confirmedTransactions.length ? (
          <LowerSection>
            <AutoRow mb={'20px'} style={{ justifyContent: 'space-between' }}>
              <TYPE.body
                color={theme.colors.text1}
                fontWeight={'bold'}
                fontSize={20}
                style={{
                  lineHeight: '26px'
                }}
              >
                {t('Recent Transactions')}
              </TYPE.body>
              <WalletAction onClick={clearAllTransactionsCallback}>Clear all</WalletAction>
            </AutoRow>
            {renderTransactions(pendingTransactions)}
            {renderTransactions(confirmedTransactions)}
          </LowerSection>
        ) : (
          <LowerSection>
            <LowerSectionText>{t('Your transactions will appear here')}...</LowerSectionText>
          </LowerSection>
        )}
      </AccountSection>
    </>
  )
}
