import React, { Suspense, lazy } from 'react'
import { isDesktop } from 'react-device-detect'
import TagManager from 'react-gtm-module'
import { Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import GoogleAnalyticsReporter from 'components/analytics/GoogleAnalyticsReporter'
import AppErrorBoundary from 'components/error/AppErrorBoundary'
import Header from 'components/Header'
import Popups from 'components/Popups'
import { TitleBar } from 'components/TitleBar'
import Web3ReactManager from 'components/Web3ReactManager'
import Web3Status from 'components/Web3Status'
import { WOWSWAP_ROUTE } from 'constants/routes/routes'
import { useActiveWeb3React } from 'hooks'
import { useListenerFirebasePushNotifications } from 'hooks/usePushNotifications'
import { useRouterGuards } from 'hooks/useRouterGuards'
import { integration } from 'utils/integration'

const TradePage = lazy(() => import('./Trade'))
const Pro = lazy(() => import('./Pro'))
const Alerts = lazy(() => import('./Alerts'))
const Earn = lazy(() => import('./Earn/Earn'))
const Portfolio = lazy(() => import('./Portfolio'))
const Liquidation = lazy(() => import('./Liquidation'))
const GovernanceMenu = lazy(() => import('./Governance/GovernanceMenu'))
const GovernanceYourWallet = lazy(() => import('./Governance/GovernanceYourWallet/GovernanceYourWallet'))
const GovernanceProposals = lazy(() => import('./Governance/GovernanceProposals'))
const GovernanceProposalsItem = lazy(() => import('./Governance/GovernanceProposalsItem'))
const GovernanceFinancialModel = lazy(() => import('./Governance/GovernanceFinancialModel'))
const GovernanceEconomicModel = lazy(() => import('./Governance/GovernanceEconomicModel'))
const GovernanceDonations = lazy(() => import('./Governance/GovernanceDonations'))
const GovernanceStats = lazy(() => import('./Governance/GovernanceStats'))
const Landing = lazy(() => import('./Landing'))
const XDegen = lazy(() => import('./XDegen'))

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  min-height: 100vh;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    overflow-x: hidden;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`
const BodyWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  position: relative;
`
TagManager.initialize({
  gtmId: 'GTM-52WTN2S'
})

/* TODO:
 * Для интеграции могут быть нужны не все route-ы.
 * Возможно здесь оставлять нужные route-ы для интеграции
 * или
 * нужно разделять route-ы (это проще для back merge).
 */
function Routes() {
  const { chainId, account } = useActiveWeb3React()
  const { isCanOpenGov, isCanOpenPortfolio, isCanOpenAlerts, isCanPro } = useRouterGuards(chainId, account)
  return (
    <Switch>
      <Route exact strict key="landing" path={WOWSWAP_ROUTE.LANDING} component={Landing} />
      <Route exact strict key="xdegen" path={WOWSWAP_ROUTE.XDEGEN} component={XDegen} />
      {isCanPro && <Route exact strict key="pro" path={WOWSWAP_ROUTE.PRO} component={Pro} />}
      <Route exact strict key="swap" path={WOWSWAP_ROUTE.SWAP} component={TradePage} />
      {isCanOpenAlerts && <Route exact strict key="alerts" path={WOWSWAP_ROUTE.ALERTS} component={Alerts} />}
      <Route exact strict key="earn" path={WOWSWAP_ROUTE.EARN} component={Earn} />
      {isCanOpenPortfolio && (
        <Route exact strict key="portfolio" path={WOWSWAP_ROUTE.PORTFOLIO} component={Portfolio} />
      )}
      <Route exact strict key="liquidation" path={WOWSWAP_ROUTE.LIQUIDATION} component={Liquidation} />

      <Route exact strict key="governance-menu" path={WOWSWAP_ROUTE.GOVERNANCE_MENU} component={GovernanceMenu} />

      {isCanOpenGov && (
        <Route
          exact
          strict
          key="governance-your-wallet"
          path={WOWSWAP_ROUTE.GOVERNANCE_YOUR_WALLET}
          component={GovernanceYourWallet}
        />
      )}

      {isCanOpenGov && (
        <Route
          exact
          strict
          key="governance-proposals"
          path={WOWSWAP_ROUTE.GOVERNANCE_PROPOSALS}
          component={GovernanceProposals}
        />
      )}
      {isCanOpenGov && (
        <Route
          exact
          strict
          key="governance-proposals-item"
          path={WOWSWAP_ROUTE.GOVERNANCE_PROPOSALS_ITEM}
          component={GovernanceProposalsItem}
        />
      )}
      {isCanOpenGov && (
        <Route
          exact
          strict
          key="governance-financial-model"
          path={WOWSWAP_ROUTE.GOVERNANCE_FINANCIAL_MODEL}
          component={GovernanceFinancialModel}
        />
      )}
      {isCanOpenGov && (
        <Route
          exact
          strict
          key="governance-economic-model"
          path={WOWSWAP_ROUTE.GOVERNANCE_ECONOMIC_MODEL}
          component={GovernanceEconomicModel}
        />
      )}
      {isCanOpenGov && (
        <Route
          exact
          strict
          key="governance-donations"
          path={WOWSWAP_ROUTE.GOVERNANCE_DONATIONS}
          component={GovernanceDonations}
        />
      )}
      {isCanOpenGov && (
        <Route exact strict key="governance-stats" path={WOWSWAP_ROUTE.GOVERNANCE_STATS} component={GovernanceStats} />
      )}

      <Redirect to={integration ? WOWSWAP_ROUTE.SWAP : WOWSWAP_ROUTE.LANDING} />
    </Switch>
  )
}

export default function App() {
  useListenerFirebasePushNotifications()
  const isIframe = process.env.REACT_APP_IS_IFRAME

  return (
    <Suspense fallback={null}>
      <AppErrorBoundary>
        <Route component={GoogleAnalyticsReporter} />
        <AppWrapper>
          {isIframe ? (
            <Web3Status showInnerStatus={false} />
          ) : (
            <>
              {isDesktop && <TitleBar />}
              <HeaderWrapper>
                <Header />
              </HeaderWrapper>
            </>
          )}
          <BodyWrapper>
            <Popups />
            <Web3ReactManager>
              <Routes />
            </Web3ReactManager>
          </BodyWrapper>
        </AppWrapper>
      </AppErrorBoundary>
    </Suspense>
  )
}
