import { Tags, TokenInfo, TokenList } from '@uniswap/token-lists'
import { ChainId, Currency, Token } from '@wowswap-io/wowswap-sdk'
import { getAddress } from 'ethers/lib/utils'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { AppState } from 'state'

type TagDetails = Tags[keyof Tags]
interface TagInfo extends TagDetails {
  id: string
}

/**
 * Token instances created from token info.
 */
export class WrappedTokenInfo extends Token {
  public readonly tokenInfo: TokenInfo
  public readonly tags: TagInfo[]
  constructor(tokenInfo: TokenInfo, tags: TagInfo[]) {
    super(tokenInfo.chainId, getAddress(tokenInfo.address), tokenInfo.decimals, tokenInfo.symbol, tokenInfo.name)
    this.tokenInfo = tokenInfo
    this.tags = tags
  }
  public get logoURI(): string | undefined {
    return this.tokenInfo.logoURI
  }
  public get getTokenAddress() {
    if (!this.tokenInfo?.lendable) {
      if (this.tokenInfo?.proxyAddress) {
        return this.tokenInfo?.proxyAddress
      }

      if (this.tokenInfo?.proxyName && this.tokenInfo?.nameToProxy) {
        return this.tokenInfo?.nameToProxy[this.tokenInfo?.proxyName]
      }
    }
    return this.tokenInfo.address
  }
}

type TokenAddressMap = Readonly<{ [chainId in ChainId]: Readonly<{ [tokenAddress: string]: WrappedTokenInfo }> }>

/**
 * An empty result, useful as a default.
 */
const EMPTY_LIST: TokenAddressMap = {
  [ChainId.ETHEREUM]: {},
  [ChainId.ROPSTEN]: {},
  [ChainId.MAINNET]: {},
  [ChainId.MATIC]: {},
  [ChainId.HECO]: {},
  [ChainId.BSCTESTNET]: {},
  [ChainId.LOCALNET]: {},
  [ChainId.AVALANCE]: {},
  [ChainId.FUJI]: {},
  [ChainId.IOTEX]: {},
  [ChainId.ANDROMEDA]: {},
  [ChainId.STARDUST]: {},
  [ChainId.KAVATESTNET]: {},
  [ChainId.KAVA]: {}
}

const listCache: WeakMap<TokenList, TokenAddressMap> | null =
  typeof WeakMap !== 'undefined' ? new WeakMap<TokenList, TokenAddressMap>() : null

function listToTokenMap(list: TokenList): TokenAddressMap {
  const result = listCache?.get(list)
  if (result) return result

  const map = list.tokens.reduce<TokenAddressMap>(
    (tokenMap, tokenInfo) => {
      const tags: TagInfo[] =
        tokenInfo.tags
          ?.map(tagId => {
            if (!list.tags?.[tagId]) return undefined
            return { ...list.tags[tagId], id: tagId }
          })
          ?.filter((x): x is TagInfo => Boolean(x)) ?? []
      const token = createTokens(tokenInfo, tags, list)
      if (tokenMap[token.chainId][token.address] !== undefined) {
        console.log({
          address: token.address,
          symbol: token.symbol
        })
        throw Error('Duplicate tokens.')
      }
      return {
        ...tokenMap,
        [token.chainId]: {
          ...tokenMap[token.chainId],
          [token.address]: token
        }
      }
    },
    { ...EMPTY_LIST }
  )
  listCache?.set(list, map)
  return map
}

function useTokenList(url: string | undefined): TokenAddressMap {
  const lists = useSelector<AppState, AppState['lists']['byUrl']>(state => state.lists.byUrl)
  return useMemo(() => {
    if (!url) return EMPTY_LIST
    const current = lists[url]?.current
    if (!current) return EMPTY_LIST
    try {
      return listToTokenMap(current)
    } catch (error) {
      console.error('Could not show token list due to error', error)
      return EMPTY_LIST
    }
  }, [lists, url])
}

function useSelectedListUrl(): string | undefined {
  return useSelector<AppState, AppState['lists']['selectedListUrl']>(state => state.lists.selectedListUrl)
}

export function useSelectedTokenList(): TokenAddressMap {
  return useTokenList(useSelectedListUrl())
}

function createTokens(tokenInfo: TokenInfo, tags: TagInfo[], list: TokenList): WrappedTokenInfo {
  const BASE_TOKEN_SYMBOL = Currency.ETHER_MAP[tokenInfo.chainId as ChainId].symbol!

  const proxyNames = Object.entries(tokenInfo.proxies).reduce<Record<string, string>>((acc, [proxyHost]) => {
    const proxyToken =
      list.tokens.find(token => token.address.toLowerCase() === proxyHost.toLowerCase())?.symbol || BASE_TOKEN_SYMBOL
    acc[proxyHost] = `${tokenInfo.symbol}_${proxyToken}`
    return acc
  }, {})
  const nameToProxy = Object.entries(tokenInfo.proxies).reduce<Record<string, string>>((acc, [proxyHost, proxyAdr]) => {
    const proxyToken =
      list.tokens.find(token => token.address.toLowerCase() === proxyHost.toLowerCase())?.symbol || BASE_TOKEN_SYMBOL
    acc[`${tokenInfo.symbol}_${proxyToken}`] = proxyAdr
    return acc
  }, {})
  const nameToHosts = Object.entries(tokenInfo.proxies).reduce<Record<string, string>>((acc, [proxyHost]) => {
    const proxyToken =
      list.tokens.find(token => token.address.toLowerCase() === proxyHost.toLowerCase())?.symbol || BASE_TOKEN_SYMBOL
    acc[`${tokenInfo.symbol}_${proxyToken}`] = proxyHost
    return acc
  }, {})

  return new WrappedTokenInfo({ ...tokenInfo, proxyNames, nameToProxy, nameToHosts }, tags)
}
