import React from 'react'

import Modal from 'components/Modal'
import { TransactionErrorContent } from 'components/transaction/TransactionErrorContent'

interface TransactionErrorModalProps {
  isOpen: boolean
  onDismiss: () => void
  errorMessage: string
  isExpandable?: boolean
  expandableContent: React.ReactNode
}

export default function TransactionErrorModal({
  isOpen,
  onDismiss,
  errorMessage,
  isExpandable = false,
  expandableContent
}: TransactionErrorModalProps) {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      <TransactionErrorContent
        onDismiss={onDismiss}
        message={errorMessage}
        isExpandable={isExpandable}
        expandableContent={expandableContent}
      ></TransactionErrorContent>
    </Modal>
  )
}
