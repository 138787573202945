import { ChainId, CurrencyAmount, id, JSBI, WETH } from '@wowswap-io/wowswap-sdk'
import { useMemo } from 'react'

import { useMulticallContract } from 'hooks/useContract'
import { WrappedTokenInfo } from 'state/lists/hooks'
import { useSingleContractMultipleData } from 'state/multicall/hooks'
import { isAddress } from 'utils'

/**
 * Returns a map of the given addresses to their eventually consistent ETH balances.
 */
export function useETHBalances(
  uncheckedAddresses?: (string | undefined)[]
): { [address: string]: CurrencyAmount | undefined } {
  const multicallContract = useMulticallContract()

  const addresses: string[] = useMemo(
    () =>
      uncheckedAddresses
        ? uncheckedAddresses
            .map(isAddress)
            .filter((a): a is string => a !== false)
            .sort()
        : [],
    [uncheckedAddresses]
  )

  const results = useSingleContractMultipleData(
    multicallContract,
    'getEthBalance',
    addresses.map(address => [address])
  )

  return useMemo(
    () =>
      addresses.reduce<{ [address: string]: CurrencyAmount }>((memo, address, i) => {
        const value = results?.[i]?.result?.[0]
        if (value) memo[address] = CurrencyAmount.ether(JSBI.BigInt(value.toString()))
        return memo
      }, {}),
    [addresses, results]
  )
}

export function getDefaultToken(chainId?: ChainId): WrappedTokenInfo {
  const weth = WETH[chainId || id.getId()]
  return new WrappedTokenInfo(
    {
      name: weth.name!,
      symbol: weth.symbol!,
      address: weth.address,
      chainId: weth.chainId,
      decimals: weth.decimals,
      lendable: true,
      native: true,
      proxies: {}
    },
    []
  )
}
