import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import { ReactComponent as ApprovingArrow } from 'assets/images/approvingArrow.svg'
import { ReactComponent as ApprovingArrowLinear } from 'assets/images/approvingArrowLinear.svg'
import { ReactComponent as Check } from 'assets/images/check.svg'
import { ReactComponent as CloseLinearIcon } from 'assets/images/close-metis.svg'
import { ReactComponent as Close } from 'assets/images/close.svg'
import { ReactComponent as Exchange } from 'assets/images/exchange.svg'
import { ReactComponent as Warning } from 'assets/images/warning.svg'

const BaseIconStyle = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;

  > svg {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.colors.text1};
    transition: all 0.4s ease;

    &:hover {
      fill: ${({ theme }) => theme.colors.primary1};
      transition: all 0.4s ease;
    }
  }

  ${({ theme }) =>
    theme.mode === 'metis' &&
    `
    width: 12px;
    height: 12px;

    > svg {
      width: 12px;
      height: 12px;
      fill: ${theme.colors.primary1};

      &:hover {
        fill: ${theme.colors.text1};
      }
    }
  `}

  ${({ theme }) =>
    theme.mode === 'dark' &&
    `
    width: 20px;
    height: 20px;

    > svg {
      width: 20px;
      height: 20px;
    }
  `}
`

const ExchangeStyle = styled(Exchange)`
  width: 9px;
  height: 12px;
`

const ApprovingArrowStyle = styled.div`
  > svg {
    width: 142px;
    height: 142px;
  }
`

const CheckStyle = styled(Check)`
  width: 18px;
  height: 18px;
`

const WarningStyle = styled(Warning)`
  width: 19px;
  height: 16px;
`

export function IconClose() {
  const { mode } = useContext(ThemeContext)
  const CloseIcon = mode === 'metis' || mode === 'dark' ? CloseLinearIcon : Close

  return (
    <BaseIconStyle>
      <CloseIcon />
    </BaseIconStyle>
  )
}

export function IconExchange() {
  return <ExchangeStyle />
}

export function IconApprovingArrow() {
  const { mode } = useContext(ThemeContext)
  const ArrowImage = mode === 'dark' ? ApprovingArrowLinear : ApprovingArrow

  return (
    <ApprovingArrowStyle>
      <ArrowImage />
    </ApprovingArrowStyle>
  )
}

export function IconCheck() {
  return <CheckStyle />
}

export function IconWarning() {
  return <WarningStyle />
}
