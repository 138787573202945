import { css } from 'styled-components'

import { black, blue, green, grey1, grey2, light, orange, red, white, dark } from './colors'

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 600,
  upToMedium: 960,
  upToLarge: 1280,
  aboveExtraSmall: 500,
  aboveSmall: 600,
  aboveMedium: 960,
  aboveLarge: 1280
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => {
      if (size.includes('upTo'))
        return css`
          @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
            ${css(a, b, c)}
          }
        `

      if (size.includes('above'))
        return css`
          @media (min-width: ${(MEDIA_WIDTHS as any)[size]}px) {
            ${css(a, b, c)}
          }
        `
      return css``
    }
    return accumulator
  },
  {}
) as any

export default {
  colors: { black, dark, blue, green, grey1, grey2, light, orange, red, white },
  background: {
    image: `#D646B4`
  },
  grids: {
    sm: 8,
    md: 12,
    lg: 24
  },
  mediaWidth: mediaWidthTemplates,
  // css snippets
  flexColumnNoWrap: css`
    display: flex;
    flex-flow: column nowrap;
  `,
  flexRowNoWrap: css`
    display: flex;
    flex-flow: row nowrap;
  `,
  typography: {
    fontFamily: {
      primary: "'Retro', sans-serif",
      secondary: "'Abadi MT Std', sans-serif"
    }
  },
  shape: {
    borderRadius: {
      l: '42px',
      m: '20px',
      s: '10px'
    }
  },

  paddings: {
    globalBodyTop: '50px'
  }
}
