import { ChainId } from '@wowswap-io/wowswap-sdk'

import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: '0xf2D1b2F8ef9119B37e283Cc7FAB44c01C266B1B6', // TODO
  [ChainId.ROPSTEN]: '0x91f78dd8bbE0B3B59749Bdf06B454bE6955fe9D1', // TODO
  [ChainId.MAINNET]: '0x8BB95a1E0D9556997Ab9B56Fc3DE62B7110a95E8',
  [ChainId.BSCTESTNET]: '0xE4B841A643c54c0F117E531a80b0897711986abB',
  [ChainId.LOCALNET]: '0x0B7B00f5953BCbef0DD94156B01cF8336540e1bf',
  [ChainId.MATIC]: '0xeCd953FCC60D9bE066863643591Bb73d115F2cC6',
  [ChainId.HECO]: '0x72279055bA0f09FC4efA69Ac5A1A3511bD0e4d03',
  [ChainId.AVALANCE]: '0x73EA8D6Fa5b57c0f94ab387f77972576FC9cDec1',
  [ChainId.FUJI]: '',
  [ChainId.IOTEX]: '0x617b1289d9E8389cd3255f475E44cD60f0a981bb',
  [ChainId.ANDROMEDA]: '0xA95f470fb554eC18e68AA1B2562A99b0079601C9',
  [ChainId.STARDUST]: '0xA95f470fb554eC18e68AA1B2562A99b0079601C9',
  [ChainId.KAVATESTNET]: '0xf93e9163b52F71Ce1d09eA2b8757DaC0483a05FE',
  [ChainId.KAVA]: '0x9aEe5fb6b496F360De5e2AA78f08D11B59D923Bf'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
