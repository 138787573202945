import React, { useContext } from 'react'
import { AlertTriangle } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'

import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import ExpandablePanel from 'components/ExpandablePanel'
import { RowBetween, RowCenter } from 'components/Row'
import { CloseIcon } from 'components/Shared'
import { BottomSection, Section, WraperClose, Wrapper, Title, Message } from 'components/transaction/styleds'

type TransactionErrorContentProps = {
  message: string
  onDismiss: () => void
  isExpandable?: boolean
  expandableContent?: React.ReactNode
}

export function TransactionErrorContent({
  message,
  onDismiss,
  isExpandable = false,
  expandableContent
}: TransactionErrorContentProps) {
  const theme = useContext(ThemeContext)
  const isMetisMode = theme.mode === 'metis'
  const RowTitle = isMetisMode ? RowCenter : RowBetween

  const { t } = useTranslation()

  return (
    <Wrapper>
      <Section>
        <RowTitle>
          <WraperClose>
            <CloseIcon onClick={onDismiss} />
          </WraperClose>
          <Title>{t('Error')}</Title>
        </RowTitle>
        <AutoColumn style={{ marginTop: 20, padding: '2rem 0' }} gap="24px" justify="center">
          <AlertTriangle color={theme.colors.primary4} style={{ strokeWidth: 1.5 }} size={64} />
          <Message>{message}</Message>
        </AutoColumn>
        {isExpandable && (
          <ExpandablePanel style={{ marginBottom: '20px' }} label={`${t('Stack error')}:`}>
            {expandableContent}
          </ExpandablePanel>
        )}
      </Section>
      <BottomSection gap="12px">
        <ButtonPrimary onClick={onDismiss}>{t('Dismiss')}</ButtonPrimary>
      </BottomSection>
    </Wrapper>
  )
}
