import { useCallback, useEffect, useState } from 'react'

import { FirebasPushNotificationsService } from 'services/notifications/push/firebase/pushNotifications.service'

export function useFirebasePushNotifications() {
  const [isSupported, setIsSupported] = useState(false)

  useEffect(() => {
    setIsSupported(false)
    FirebasPushNotificationsService.isSupported().then(isSupport => {
      setIsSupported(isSupport)
    })
  }, [setIsSupported])

  const getNotificationToken = useCallback(async () => {
    return await FirebasPushNotificationsService.getNotificationToken()
  }, [])

  const deleteNotificationToken = useCallback(async () => {
    return await FirebasPushNotificationsService.deleteNotificationToken()
  }, [])

  const onNotificationListener = useCallback(() => {
    return FirebasPushNotificationsService.onNotificationListener()
  }, [])

  return {
    isSupported,
    getNotificationToken,
    deleteNotificationToken,
    onNotificationListener
  }
}

export function useListenerFirebasePushNotifications() {
  const { isSupported, onNotificationListener } = useFirebasePushNotifications()

  useEffect(() => {
    if (!isSupported) {
      return
    }

    return onNotificationListener()
  }, [isSupported, onNotificationListener])
}
