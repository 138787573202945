import { createAction } from '@reduxjs/toolkit'
import { ChainId } from '@wowswap-io/wowswap-sdk'

import { Field } from './types'

export const selectCurrency = createAction<{ field: Field; currency: string }>('trade/selectCurrency')
export const switchCurrencies = createAction<void>('trade/switchCurrencies')
export const typeInput = createAction<{ typedValue: string }>('trade/typeInput')

export const setClosePositionInfo = createAction<{
  amountOut: string
  debtPayable: string
  protocolFee: string
  profit: string
}>('trade/setClosePositionInfo')

export const setOpenPositionInfo = createAction<{
  borrowAmount: string | null
  borrowRate: string | null
  liquidationCost: string | null
  maxBorrow: string | null
}>('trade/setOpenPositionInfo')
export const setLeverage = createAction<{ leverage: number }>('trade/setLeverage')
export const setIsShortTrade = createAction<{ isShortTrade: boolean }>('trade/setIsShortTrade')
export const resetSwapState = createAction('trade/resetSwapState')
export const setInitialTokens = createAction<{ chainId: ChainId }>('trade/setInitialTokens')
export const setMaxPositionAmount = createAction<{ isMaxPosition: boolean }>('trade/setMaxPositionAmount')
export const setIsActiveLimitOrders = createAction<{ isActiveLimitOrders: boolean }>('trade/setIsActiveLimitOrders')
export const setStopLoss = createAction<{ stopLoss: number }>('trade/setStopLoss')
export const setTakeProfit = createAction<{ takeProfit: number }>('trade/setTakeProfit')
