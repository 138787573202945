import { Web3Provider } from '@ethersproject/providers'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { ChainId } from '@wowswap-io/wowswap-sdk'

import { NETWORK_PARAMS, SUPPORTED_WALLET_NAMES } from 'constants/index'

import { InjectedFakeHardwareConnector } from './InjectedFakeHardwareConnector'
import { NetworkConnector } from './NetworkConnector'

const DEFAULT_CHAIN_ID = ChainId.MAINNET

const DEFAULT_SUPPORTED_CHAIN_IDS = [
  ChainId.ETHEREUM,
  ChainId.MAINNET,
  ChainId.LOCALNET,
  ChainId.HECO,
  ChainId.MATIC,
  ChainId.IOTEX,
  ChainId.AVALANCE,
  // ChainId.KAVATESTNET,
  ChainId.KAVA
]

const envChainId: null | number = Number(process.env.REACT_APP_CHAIN_ID) || null
let envSupportedChainIds: null | number[] = null

try {
  envSupportedChainIds = JSON.parse(`${process.env.REACT_APP_SUPPORTED_CHAIN_IDS}`).map(Number)
  if (!Array.isArray(envSupportedChainIds)) envSupportedChainIds = null
} catch (e) {
  //
}

export const defaultChainId = envChainId || DEFAULT_CHAIN_ID
const supportedChainIds = envSupportedChainIds || DEFAULT_SUPPORTED_CHAIN_IDS

if (!supportedChainIds.includes(defaultChainId)) supportedChainIds.push(defaultChainId)

const rpcUrlsByNetworks: Record<string, string> = {}

for (const key in NETWORK_PARAMS) {
  const chainId = Number(NETWORK_PARAMS[(key as unknown) as keyof typeof NETWORK_PARAMS]?.chainId)
  if (!supportedChainIds.includes(chainId)) continue

  const rpcUrl = NETWORK_PARAMS[(key as unknown) as keyof typeof NETWORK_PARAMS]?.rpcUrls[0]
  if (rpcUrl) rpcUrlsByNetworks[key] = rpcUrl
}

export const network = new NetworkConnector({
  urls: rpcUrlsByNetworks,
  defaultChainId
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

const injected = new InjectedConnector({ supportedChainIds })

const injectedFakeHardware = new InjectedFakeHardwareConnector({ supportedChainIds })

const walletconnect = new WalletConnectConnector({
  rpc: rpcUrlsByNetworks,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true
})

export const connectorsByWallets: { [key in SUPPORTED_WALLET_NAMES]: AbstractConnector } = {
  [SUPPORTED_WALLET_NAMES.INJECTED]: injected,
  [SUPPORTED_WALLET_NAMES.METAMASK]: injected,
  [SUPPORTED_WALLET_NAMES.METAMASK_FAKE_HARDWARE]: injectedFakeHardware,
  [SUPPORTED_WALLET_NAMES.METAMASK_MOBILE_DEEP_LINK]: injected,
  [SUPPORTED_WALLET_NAMES.COIN98]: injected,
  [SUPPORTED_WALLET_NAMES.MATH_WALLET]: injected,
  [SUPPORTED_WALLET_NAMES.TRUST_WALLET]: injected,
  [SUPPORTED_WALLET_NAMES.WALLETCONNECT]: walletconnect
}
