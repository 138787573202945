import { Currency } from '@wowswap-io/wowswap-sdk'
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ThemeContext } from 'styled-components'

import AuditLogoLight from 'assets/images/audit-light.svg'
import AuditLogo from 'assets/images/audit.svg'
import LogoMobile from 'assets/svg/logo--mobile.svg'
import LogoDark from 'assets/svg/logo-dark.svg'
import LogoWhite from 'assets/svg/logo-white.svg'
import ChainWalletModal from 'components/ChainWalletModal'
import BuyWOWButton from 'components/Header/BuyWOWButton'
import { Nav } from 'components/Header/Nav'
import { Network } from 'components/Header/Network'
import { SideMenu } from 'components/Header/SideMenu'
import Web3Status from 'components/Web3Status'
import { useActiveWeb3React } from 'hooks'
import useInterval from 'hooks/useInterval'
import { AppDispatch } from 'state'
import { useAppNetwork } from 'state/appNetwork/hooks'
import { resetStakeState } from 'state/stake/actions'
import { resetSwapState, setInitialTokens } from 'state/trade/actions'
import { useETHBalances } from 'state/wallet/hooks'

import * as S from './styleds'

const UPDATE_WOW_PRICE_INTERVAL_MSEC = 3 * 60 * 1000

const AUDIT_REPORT_URL = 'https://hacken.io/wp-content/uploads/2021/06/WowSwap_03062021SCAudit_Report_2.pdf'

export default function Header() {
  const dispatch = useDispatch<AppDispatch>()
  const theme = useContext(ThemeContext)
  const { account } = useActiveWeb3React()
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const { appChainId } = useAppNetwork()
  const [WOWPrice, setWOWPrice] = useState<number | null>(null)
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(resetSwapState())
    dispatch(resetStakeState())
    dispatch(setInitialTokens({ chainId: appChainId }))
  }, [appChainId, dispatch])

  useEffect(() => {
    dispatch(resetStakeState())
  }, [dispatch, account])

  useInterval(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_CHARTS_URL}/token/price?token=WOW&currency=USD`)
      const WOWPrice = await response.json()
      setWOWPrice(+WOWPrice)
    } catch (err) {
      console.error(err)
    }
  }, UPDATE_WOW_PRICE_INTERVAL_MSEC)

  return (
    <S.HeaderFrame>
      <S.Title href="/">
        <S.Icon>
          {theme.mode === 'dark' ? (
            <img src={LogoDark} alt={t('WOWswap-Logo')} />
          ) : (
            <img src={LogoWhite} alt={t('WOWswap-Logo')} />
          )}
        </S.Icon>
        <S.IconMobile>
          <img src={LogoMobile} alt={t('WOWswap')} />
        </S.IconMobile>
      </S.Title>

      <Nav position="top" />

      <S.HeaderControls>
        {!account && (
          <S.AuditLink target="_blank" rel="noopener noreferrer" href={AUDIT_REPORT_URL}>
            <img src={theme.mode === 'dark' ? AuditLogoLight : AuditLogo} alt="Hacken" />
          </S.AuditLink>
        )}
        <S.BuyWOWWrapper>
          <BuyWOWButton WOWPrice={WOWPrice} />
        </S.BuyWOWWrapper>
        <Network />
        <S.AccountElement>
          {account && userEthBalance ? (
            <S.BalanceText
              style={{
                flexShrink: 0
              }}
            >
              {userEthBalance?.toSignificant(4)} {Currency.getBaseCurrency().symbol}
            </S.BalanceText>
          ) : null}
          <Web3Status />
        </S.AccountElement>
      </S.HeaderControls>

      <S.MobileHeaderElementWrap>
        <SideMenu WOWPrice={WOWPrice} />
      </S.MobileHeaderElementWrap>

      <ChainWalletModal />
    </S.HeaderFrame>
  )
}
