import 'i18n'
import 'inter-ui'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import { ChainId } from '@wowswap-io/wowswap-sdk'
import React, { StrictMode } from 'react'
import { isMobile, isDesktop } from 'react-device-detect'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { Provider } from 'react-redux'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { DefaultTheme, ThemeProvider as StyledThemeProvider } from 'styled-components'

import TikTokPixel from 'components/analytics/TikTokPixel'
import AppMonitoringMetadataProvider from 'components/monitoring/AppMonitoringMetadataProvider'
import { FixedGlobalStyle, ThemedGlobalStyle } from 'components/Shared'
import { NetworkContextName } from 'constants/index'
import App from 'pages/App'
import AppMonitoringService from 'services/monitoring/monitoring.service'
import store from 'state'
import ApplicationUpdater from 'state/application/updater'
import { AppNetworkProvider } from 'state/appNetwork/AppNetworkContext'
import { useAppNetwork } from 'state/appNetwork/hooks'
import ListsUpdater from 'state/lists/updater'
import MulticallUpdater from 'state/multicall/updater'
import TransactionUpdater from 'state/transactions/updater'
import {
  lightTheme,
  maticTheme,
  hecoTheme,
  ethereumTheme,
  avalancheTheme,
  iotexTheme,
  metisTheme,
  darkTheme,
  kavaTheme
} from 'theme'
import getLibrary from 'utils/getLibrary'
import { integration, Integration } from 'utils/integration'

AppMonitoringService.init()

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

if ('ethereum' in window) {
  ;(window.ethereum as any).autoRefreshOnNetworkChange = false
}

const GOOGLE_ANALYTICS_ID: string | undefined = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
if (typeof GOOGLE_ANALYTICS_ID === 'string') {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID)
  ReactGA.set({
    customBrowserType: !isMobile ? 'desktop' : 'web3' in window || 'ethereum' in window ? 'mobileWeb3' : 'mobileRegular'
  })
} else {
  ReactGA.initialize('test', { testMode: true, debug: true })
}

window.addEventListener('error', error => {
  localStorage && localStorage.removeItem('redux_localstorage_simple_lists')
  ReactGA.exception({
    description: `${error.message} @ ${error.filename}:${error.lineno}:${error.colno}`,
    fatal: true
  })
})

const TIK_TOK_PIXEL_ID: string | undefined = process.env.REACT_APP_TIK_TOK_PIXEL_ID
if (TIK_TOK_PIXEL_ID && typeof TIK_TOK_PIXEL_ID === 'string') {
  TikTokPixel.initialize(TIK_TOK_PIXEL_ID)
}

function Updaters() {
  return (
    <>
      <ListsUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  )
}

const THEMES: {
  [key in ChainId]?: DefaultTheme
} = {
  [ChainId.MATIC]: maticTheme,
  [ChainId.HECO]: hecoTheme,
  [ChainId.ETHEREUM]: ethereumTheme,
  [ChainId.ROPSTEN]: ethereumTheme,
  [ChainId.AVALANCE]: avalancheTheme,
  [ChainId.FUJI]: avalancheTheme,
  [ChainId.IOTEX]: iotexTheme,
  [ChainId.KAVATESTNET]: kavaTheme,
  [ChainId.KAVA]: kavaTheme
}

const INTEGRATION_THEMES: Record<Integration, DefaultTheme> = {
  METIS: metisTheme
}

function ThemeProvider({ children }: { children?: React.ReactNode }) {
  const { appChainId } = useAppNetwork()
  const location = useLocation()

  let theme: DefaultTheme
  if (isDesktop && location.pathname === '/pro') {
    theme = darkTheme
  } else if (integration) {
    theme = INTEGRATION_THEMES[integration]
  } else {
    theme = THEMES[appChainId] || lightTheme
  }

  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
}

function Container() {
  return (
    <StrictMode>
      {/* TODO:
       * Для интеграции через iframe раньше использовалось:
       *   basename={inIframe ? '/iframe' : '/'}.
       * Нужно уточнить и вернуть, если нужно.
       */}
      <BrowserRouter>
        <FixedGlobalStyle />
        <Web3ReactProvider getLibrary={getLibrary}>
          <Web3ProviderNetwork getLibrary={getLibrary}>
            <Provider store={store}>
              <AppNetworkProvider>
                <Updaters />
                <ThemeProvider>
                  <ThemedGlobalStyle />
                  <AppMonitoringMetadataProvider />
                  <App />
                </ThemeProvider>
              </AppNetworkProvider>
            </Provider>
          </Web3ProviderNetwork>
        </Web3ReactProvider>
      </BrowserRouter>
    </StrictMode>
  )
}

ReactDOM.render(<Container />, document.getElementById('root'))
