import { ChainId } from '@wowswap-io/wowswap-sdk'
import React, { createContext, useState, useEffect, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { LOCAL_STORAGE_KEY, SEARCH_PARAM_KEY } from 'constants/index'
import { useActiveWeb3React } from 'hooks'
import useSwitchChainOnMetamask from 'hooks/useChangeChain'
import { useLocalStorage } from 'hooks/useLocalStorage'
import useParsedQueryString from 'hooks/useParsedQueryString'
import { redirectByNetworkIfNeeded } from 'utils/networkRedirect'

const DEFAULT_CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID) || ChainId.MAINNET

type AppNetworkState = {
  appChainId: ChainId
  changeAppNetwork: (chainId: ChainId) => void
}

const defaultState: AppNetworkState = {
  appChainId: DEFAULT_CHAIN_ID,
  changeAppNetwork: () => void null
}

export const AppNetworkContext = createContext(defaultState)

const validateChainId = (chainId: unknown): ChainId | null => {
  return ChainId[Number(chainId)] !== undefined ? Number(chainId) : null
}

export const AppNetworkProvider: React.FC = ({ children }) => {
  const { chainId, account } = useActiveWeb3React()
  const { changeNetwork: changeWalletNetwork } = useSwitchChainOnMetamask()
  const locationSearch = useParsedQueryString()
  const { getFromLocalStore, setToLocalStore } = useLocalStorage()
  const location = useLocation()
  const history = useHistory()

  const walletChainId = validateChainId(chainId)
  const chainIdFromSearch = validateChainId(locationSearch[SEARCH_PARAM_KEY.CHAIN])
  const chainIdFromLocalStorage = validateChainId(getFromLocalStore(LOCAL_STORAGE_KEY.APP_CHAIN_ID))

  const [appChainId, setAppChainId] = useState<ChainId>(
    walletChainId || chainIdFromSearch || chainIdFromLocalStorage || DEFAULT_CHAIN_ID
  )

  const changeAppNetwork = useCallback(
    (chainId: number) => {
      if (!validateChainId(chainId)) return
      setToLocalStore(LOCAL_STORAGE_KEY.APP_CHAIN_ID, String(chainId))
      setAppChainId(chainId)
    },
    [setToLocalStore]
  )

  const resetChainSearchQuery = () => {
    const queryParams = new URLSearchParams(location.search)
    queryParams.delete(SEARCH_PARAM_KEY.CHAIN)
    history.replace({ search: queryParams.toString() })
  }

  useEffect(() => {
    if (walletChainId) {
      redirectByNetworkIfNeeded(ChainId[walletChainId] as keyof typeof ChainId)
      changeAppNetwork(walletChainId)
    }
  }, [walletChainId, changeAppNetwork])

  useEffect(() => {
    const handleStorageEvent = (event: StorageEvent) => {
      if (account || event.key !== LOCAL_STORAGE_KEY.APP_CHAIN_ID) return
      const newChainId = validateChainId(event.newValue)

      if (!newChainId) {
        setToLocalStore(LOCAL_STORAGE_KEY.APP_CHAIN_ID, String(appChainId))
      } else {
        setAppChainId(newChainId)
      }
    }

    window.addEventListener('storage', handleStorageEvent)
    return () => window.removeEventListener('storage', handleStorageEvent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!chainIdFromSearch) return
    if (account) {
      changeWalletNetwork(chainIdFromSearch)
    } else {
      changeAppNetwork(chainIdFromSearch)
    }
    resetChainSearchQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainIdFromSearch])

  useEffect(() => {
    if (account) {
      changeWalletNetwork(appChainId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  return <AppNetworkContext.Provider value={{ appChainId, changeAppNetwork }}>{children}</AppNetworkContext.Provider>
}
