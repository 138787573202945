import { createAsyncThunk } from '@reduxjs/toolkit'

import NotificationsHttpService from 'services/api/http/notifications/notifications.service'

export const fetchUserNotifications = createAsyncThunk('notifications/fetchUser', async (wallet: string) => {
  const response = await NotificationsHttpService.fetchUserNotifications(wallet)
  return await response.json()
})

export const setChainIdsNotifications = createAsyncThunk(
  'notifications/setChainIds',
  async ({ wallet, chainIds }: { wallet: string; chainIds: string[] }) => {
    const response = await NotificationsHttpService.updateUserNotifications(wallet, { chainIds })
    return await response.json()
  }
)

export const setProfitNotifications = createAsyncThunk(
  'notifications/setProfit',
  async ({ wallet, profitValues, chainIds }: { wallet: string; profitValues: string[]; chainIds: string[] }) => {
    const response = await NotificationsHttpService.updateUserNotifications(wallet, { profitValues, chainIds })
    return await response.json()
  }
)

export const setHealthNotifications = createAsyncThunk(
  'notifications/setHealth',
  async ({
    wallet,
    positionHealthValues,
    chainIds
  }: {
    wallet: string
    positionHealthValues: string[]
    chainIds: string[]
  }) => {
    const response = await NotificationsHttpService.updateUserNotifications(wallet, { positionHealthValues, chainIds })
    return await response.json()
  }
)

export const enableLiquidationNotifications = createAsyncThunk(
  'notifications/enableLiquidation',
  async ({
    wallet,
    liquidationEnabled,
    chainIds
  }: {
    wallet: string
    liquidationEnabled: boolean
    chainIds: string[]
  }) => {
    const response = await NotificationsHttpService.updateUserNotifications(wallet, { liquidationEnabled, chainIds })
    return await response.json()
  }
)

export const setAPYNotifications = createAsyncThunk(
  'notifications/setAPY',
  async ({ wallet, apyValues, chainIds }: { wallet: string; apyValues: string[]; chainIds: string[] }) => {
    const response = await NotificationsHttpService.updateUserNotifications(wallet, { apyValues, chainIds })
    return await response.json()
  }
)

export const enanbleTelegramNotifications = createAsyncThunk(
  'notifications/enanbleTelegram',
  async ({ wallet, telegramEnabled, chainIds }: { wallet: string; telegramEnabled: boolean; chainIds: string[] }) => {
    const response = await NotificationsHttpService.updateUserNotifications(wallet, { telegramEnabled, chainIds })
    return await response.json()
  }
)

export const enanblePushBrowserNotifications = createAsyncThunk(
  'notifications/enanblePushBrowser',
  async ({ wallet, pushEnabled, chainIds }: { wallet: string; pushEnabled: boolean; chainIds: string[] }) => {
    const response = await NotificationsHttpService.updateUserNotifications(wallet, { pushEnabled, chainIds })
    return await response.json()
  }
)

export const enanbleEmailNotifications = createAsyncThunk(
  'notifications/enanbleEmail',
  async ({ wallet, emailEnabled, chainIds }: { wallet: string; emailEnabled: boolean; chainIds: string[] }) => {
    const response = await NotificationsHttpService.updateUserNotifications(wallet, { emailEnabled, chainIds })
    return await response.json()
  }
)

export const resubscribeEmailNotifications = createAsyncThunk(
  'notifications/resubscribeEmail',
  async ({
    wallet,
    emails,
    email,
    redirectUrl
  }: {
    wallet: string
    emails: string[]
    email: string
    redirectUrl: string
  }) => {
    await Promise.allSettled(
      emails.map(email => NotificationsHttpService.unsubscribeNotifications(wallet, { name: email, type: 'email' }))
    )

    const response = await NotificationsHttpService.subscribeEmailNotifications(wallet, { email, redirectUrl })
    return await response.json()
  }
)

export const subcribePushNotifications = createAsyncThunk(
  'notifications/subcribePush',
  async ({ wallet, name, token }: { wallet: string; name: string; token: string }) => {
    const response = await NotificationsHttpService.subscribePushNotifications(wallet, { name, token })
    return await response.json()
  }
)

export const unsubcribePushNotifications = createAsyncThunk(
  'notifications/unsubcribePush',
  async ({ wallet, name }: { wallet: string; name: string }) => {
    const response = await NotificationsHttpService.unsubscribeNotifications(wallet, { name, type: 'push' })
    return await response.json()
  }
)
