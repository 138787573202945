import { DefaultTheme } from 'styled-components'

import light from './light'

const theme: DefaultTheme = {
  ...light,
  mode: 'kava',
  colors: {
    ...light.colors,
    bg2: '#ff423e'
  },
  background: {
    image: `linear-gradient(90deg,#ff423e 0%,#e91e1c 100%)`
  }
}

export default theme
