import { useMemo } from 'react'

export function useLocalStorage() {
  return useMemo(() => {
    return {
      getFromLocalStore: (key: string): string | null => {
        return localStorage.getItem(key)
      },
      setToLocalStore: (key: string, value: string) => {
        localStorage.setItem(key, value)
      },
      removeFromLocalStore: (key: string) => {
        localStorage.removeItem(key)
      }
    }
  }, [])
}
