import { ChainId } from '@wowswap-io/wowswap-sdk'
import { isDesktop } from 'react-device-detect'

import { GOVERNANCE_ADDRESS } from 'constants/index'

export function useRouterGuards(chainId?: ChainId | null, account?: string | null) {
  return {
    isCanOpenPortfolio: chainId && account,
    isCanOpenGov: chainId && account && Boolean(GOVERNANCE_ADDRESS[chainId]) && chainId !== ChainId.KAVA,
    isCanOpenAlerts: account && chainId !== ChainId.KAVA,
    isCanPro: isDesktop && window.innerWidth >= 990 && chainId !== ChainId.KAVA
  }
}
