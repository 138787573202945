import { useMemo } from 'react'

import { SUPPORTED_WALLETS, SUPPORTED_WALLET_NAMES } from 'constants/index'

function useIsCoin98Wallet(): boolean {
  const { ethereum } = window

  const isCoin98Enabled = useMemo(() => {
    return ethereum?.isCoin98 ?? false
  }, [ethereum])

  return isCoin98Enabled
}

function useIsMathWallet(): boolean {
  const { ethereum } = window

  const isMathWalletEnabled = useMemo(() => {
    return ethereum?.isMathWallet ?? false
  }, [ethereum])

  return isMathWalletEnabled
}

export function useIsMetaMaskWallet(): boolean {
  const { ethereum } = window
  const isCoin98Enabled = useIsCoin98Wallet()
  const isMathWalletEnabled = useIsMathWallet()

  const isMetaMaskEnabled = useMemo(() => {
    if (isCoin98Enabled || isMathWalletEnabled) {
      return false
    }

    return ethereum?.isMetaMask ?? false
  }, [ethereum, isCoin98Enabled, isMathWalletEnabled])

  return isMetaMaskEnabled
}

export function useGetWalletName(): string {
  const isCoin98Enabled = useIsCoin98Wallet()
  const isMathWalletEnabled = useIsMathWallet()
  const isMetaMaskEnabled = useIsMetaMaskWallet()

  const walletName = useMemo(() => {
    let key = SUPPORTED_WALLET_NAMES.TRUST_WALLET

    if (isCoin98Enabled) {
      key = SUPPORTED_WALLET_NAMES.COIN98
    }

    if (isMathWalletEnabled) {
      key = SUPPORTED_WALLET_NAMES.MATH_WALLET
    }

    if (isMetaMaskEnabled) {
      key = SUPPORTED_WALLET_NAMES.METAMASK
    }

    return SUPPORTED_WALLETS[key].name
  }, [isMetaMaskEnabled, isCoin98Enabled, isMathWalletEnabled])

  return walletName
}
