import { useEffect } from 'react'

import AppMonitoringService from 'services/monitoring/monitoring.service'
import { useAppNetwork } from 'state/appNetwork/hooks'

const AppMonitoringMetadataProvider = () => {
  const { appChainId } = useAppNetwork()

  useEffect(() => {
    AppMonitoringService.setTag('chainId', `${appChainId}`)
  }, [appChainId])

  return null
}

export default AppMonitoringMetadataProvider
